import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import Container, { Service } from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '../../services/error-handler.service'
import { mapState } from 'vuex'

@Service()
@Component({
  name: 'GtrUserFavorites',
  computed: {
    ...mapState('security', ['currentUser']),
    ...mapState('company', ['companies'])
  }
})
export default class GtrUserFavorites extends Vue {
  currentUser!: any;
  companies!: any;

  get tableItems (): Array<any> {
    const favorites = this.currentUser?.favorites?.companies ?? []
    return this.companies.map(company => {
      company.favorite = favorites.includes(company.uuid)
      return company
    })
  }

  /**
   * This will add a company uuid to a list in the favorites object.
   * @param company
   */
  handleFavoriteCompany (company: any): void {
    try {
      // first, favorites can be null and I suspect it might not exist for some participants so check for that.
      if (this.currentUser && this.currentUser.favorites !== null) {
        // if passes the check figure out if it is in the array.
        const index = this.currentUser.favorites.companies.indexOf(company.uuid)
        const indexOfItem = this.companies.indexOf(company)
        // add or remove.
        if (index !== -1) {
          this.currentUser.favorites.companies.splice(index, 1)
        } else {
          this.currentUser.favorites.companies.push(company.uuid)
        }

        // tag or untag the item in the list. This will trigger a rerender of the rows.
        if (indexOfItem !== -1) {
          if ('favorite' in this.companies[indexOfItem]) {
            this.companies[indexOfItem].favorite = !this.companies[indexOfItem].favorite
          } else {
            this.companies[indexOfItem].favorite = true
          }
        }
      } else {
        // otherwise initialize the favorites.
        this.currentUser.favorites = { companies: [company.uuid] }
      }
      // this.companies = this.sortCompaniesWithFavoritesFirst(this.companies)
      this.$store.dispatch('security/updateCurrentUser', { favorites: this.currentUser.favorites })
        .then((_data) => Container.get(Notification).success('Your favorites have updated.'))
        .catch(err => Container.get(Notification).error(err.message))
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  sortCompaniesWithFavoritesFirst (items: Array<Record<string, any>>, index: string, isDesc: boolean) {
    const sortItems = (a: Record<string, any>, b: Record<string, any>) => {
      const nameA = a.name.toUpperCase() // ignore upper and lowercase
      const nameB = b.name.toUpperCase() // ignore upper and lowercase
      if (isDesc[0]) {
        if (nameA < nameB) {
          return -1 // nameA comes before nameB in alphabetical order
        }
        if (nameA > nameB) {
          return 1 // nameA comes after nameB in alphabetical order
        }
      } else {
        if (nameA > nameB) {
          return -1 // nameA comes before nameB in alphabetical order
        }
        if (nameA < nameB) {
          return 1 // nameA comes after nameB in alphabetical order
        }
      }
      return 0 // names must be equal
    }

    let favorites: any[] = []
    let rest: any[] = []
    if (this.currentUser && this.currentUser.favorites && this.currentUser.favorites.companies) {
      favorites = items.filter(item => this.currentUser.favorites.companies.includes(item.uuid))
      rest = items.filter(item => !this.currentUser.favorites.companies.includes(item.uuid))
    } else {
      return items.sort(sortItems)
    }
    if (favorites.length > 0) {
      favorites.sort(sortItems)
      rest.sort(sortItems)
      return favorites.concat(rest)
    } else {
      return rest.sort(sortItems)
    }
  }
}
